import type { BaseGameItem } from '@/shared/ui/game-item';

export const topGames: BaseGameItem[] = [
  {
    href: '#',
    image: '/images/top-games/the-dollar-game.jpg',
    name: 'The Dollar Game',
  },
  {
    href: '#',
    image: '/images/top-games/pilot.jpg',
    name: 'Pilot',
  },
  {
    href: '#',
    image: '/images/top-games/buffalo-spirit.jpg',
    name: 'Buffalo Spirit',
  },
];
