import type { HTMLAttributes } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

interface LogoProps extends HTMLAttributes<HTMLAnchorElement> {
  size: 'lg' | 'sm';
}

export const Logo = ({ className, size, ...rest }: LogoProps) => {
  return (
    <a
      href="/"
      className={clsx(styles.logo, styles[size], className)}
      {...rest}
    >
      <img className={styles.img} src="/images/logo.png" alt="INRIVAL" />
    </a>
  );
};
