import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Text = {
  hero: {
    title: string;
  };
  benefits: Record<string, string> & {
    description: string;
  };
};

export const TextContext = createContext<Text | null>(null);

export const TextProvider = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation();

  const [text, setText] = useState<Text | null>(null);

  useEffect(() => {
    fetch(`/${i18n.language}.json`)
      .then((text) => text.json())
      .then(setText);
  }, [i18n.language]);

  if (!text) return null;

  return <TextContext.Provider value={text}>{children}</TextContext.Provider>;
};

export const useText = () => useContext(TextContext)!;
