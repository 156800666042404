import clsx from 'clsx';
import { Container } from '@/shared/ui/container';
import { Logo } from '@/shared/ui/logo';
import { menuItems } from './config/menu-items.config';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { LangPicker } from '@/shared/ui/lang-picker';
import { useWindowSize } from '@/shared/lib/use-window-size.lib';
import { useState, type CSSProperties } from 'react';
import { Icon } from '@/shared/ui/icon';
import { useLockedBody } from './lib/use-locked-body.lib';
import styles from './styles.module.scss';

export const Header = () => {
  const { t } = useTranslation();

  const items = menuItems(t);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const windowSize = useWindowSize();

  useLockedBody(isMenuOpened);

  const closeMenu = () => {
    setIsMenuOpened(false);
  };

  return (
    <header className={styles.root}>
      <Container className={styles.container}>
        <Logo size="lg" />
        <div
          style={{ '--vh': `${windowSize.height}px` } as CSSProperties}
          className={clsx(styles.main, { [styles.menuOpened]: isMenuOpened })}
        >
          <div className={styles.mainContainer}>
            <ul className={styles.list}>
              {items.map((item) => (
                <li className={styles.item} key={item.text}>
                  <a className={styles.link} href={item.href}>
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
            <Button
              onClick={closeMenu}
              as="a"
              href="#contacts"
              className={styles.btn}
            >
              {t('contact_us')}
            </Button>
            <LangPicker className={styles.langPicker} />
          </div>
        </div>
        <button onClick={() => setIsMenuOpened(true)} className={styles.burger}>
          <Icon name="common/burger" />
        </button>
        <div
          onClick={closeMenu}
          className={clsx(styles.overlay, { [styles.isVisible]: isMenuOpened })}
        />
      </Container>
    </header>
  );
};
