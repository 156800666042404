import { Container } from '@/shared/ui/container';
import { useTranslation } from 'react-i18next';
import { socialItems } from './config/social-items.config';
import { Icon } from '@/shared/ui/icon';
import type { FormEventHandler } from 'react';
import { useState } from 'react';
import { Button } from '@/shared/ui/button';
import styles from './styles.module.scss';
import clsx from 'clsx';

const MAX_TEXTAREA_LENGTH = 1000;

export const Contacts = () => {
  const { t, i18n: { language } } = useTranslation();

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const xhr = new XMLHttpRequest();

    setName('');
    setEmail('');
    setNumber('');
    setText('');

    xhr.open('POST', 'mail.php', true);
    xhr.send(formData);
  };

  return (
    <section className={styles.section} id="contacts">
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <h2 className={styles.title}>{t('contacts.title')}</h2>
            <p className={styles.desc}>{t('contacts.description')}</p>
            <ul className={styles.social}>
              {socialItems.map((socialItem) => (
                <li className={styles.socialItem} key={socialItem.name}>
                  <a
                    target="_blank"
                    href={socialItem.href}
                    className={styles.socialLink}
                    rel="noopener"
                  >
                    <div className={styles.socialIcon}>
                      <Icon name={socialItem.icon} />
                    </div>
                    <span>{socialItem.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.fields}>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="Имя"
                placeholder={t('contacts.inputs_placeholders.name')}
                className={styles.input}
                required
              />
              <input name="Номер телефона" type="hidden" value={number} />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="Email"
                placeholder={t('contacts.inputs_placeholders.email')}
                className={styles.input}
                required
              />
              <input
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                name="Number"
                placeholder={t('contacts.inputs_placeholders.number')}
                className={styles.input}
                required
              />
              <div className={styles.textarea}>
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  name="Text"
                  maxLength={MAX_TEXTAREA_LENGTH}
                  placeholder={t('contacts.inputs_placeholders.text')}
                  className={styles.input}
                  required
                />
                <span className={styles.textareaLength}>
                  {text.length}/{MAX_TEXTAREA_LENGTH}
                </span>
              </div>
            </div>
            <Button className={styles.btn} variant="regular">
              {t('send')}
            </Button>
          </form>
        </div>
        <img className={clsx(styles.image, styles[`image_${language}`])} src="/images/orange.png" alt="Orange" />
      </Container>
    </section>
  );
};
