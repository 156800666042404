import { Container } from '@/shared/ui/container';
import { Logo } from '@/shared/ui/logo';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.root}>
      <Container className={styles.container}>
        {/* <h2 className={styles.title}>{t('footer.title')}</h2>
        <ul className={styles.social}>
          {socialItems.map((socialItem) => (
            <li className={styles.socialItem} key={socialItem.name}>
              <a href={socialItem.href} className={styles.socialLink}>
                <img
                  className={styles.socialIcon}
                  src={`/images/colored-social/${socialItem.name}.svg`}
                  alt={socialItem.name}
                />
              </a>
            </li>
          ))}
        </ul> */}
        <div className={styles.bottom}>
          <Logo size="sm" className={styles.logo} />
          <span className={styles.copy}>{t('all-right-reserved')}</span>
        </div>
        <img className={styles.image} src="/images/cherry.png" alt="Cherry" />
      </Container>
    </footer>
  );
};
