import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TextProvider } from '@/shared/context/text.context';
import { Header } from '@/widgets/header';
import { MainBg } from '@/widgets/main-bg';
import { Hero } from '@/widgets/hero';
import { ThreeGames } from '@/widgets/three-games';
import { Benefits } from '@/widgets/benefits';
import { GridGames } from '@/widgets/grid-games';
import { ContactBtn } from '@/widgets/contact-btn';
import { Contacts } from '@/widgets/contacts';
import { Footer } from '@/widgets/footer';

export const App = () => {
  const { t } = useTranslation();

  return (
    <TextProvider>
      <HelmetProvider>
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
        </Helmet>
        <MainBg />
        <div className="site-container">
          <Header />
          <main className="main">
            <Hero />
            <ThreeGames
              gamesType="top"
              isHotGames
              title={t('top_three_games.title')}
            />
            <Benefits />
            <GridGames gamesType="myths" title={t('myths_games.title')} />
            <ContactBtn />
            <ThreeGames gamesType="irland" title={t('irland_games.title')} />
            <GridGames gamesType="fruit" title={t('fruit_games.title')} />
            <Contacts />
          </main>
          <Footer />
        </div>
      </HelmetProvider>
    </TextProvider>
  );
};
