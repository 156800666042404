import type { BaseGameItem } from '@/shared/ui/game-item';

export const mythsLegendsGames: BaseGameItem[] = [
  {
    href: '#',
    image: '/images/myths-games/solar-goddes.jpg',
    name: 'Solar Goddes',
  },
  {
    href: '#',
    image: '/images/myths-games/book-or-bastet.jpg',
    name: 'Book of Bastet',
  },
  {
    href: '#',
    image: '/images/myths-games/hot-zeus.jpg',
    name: 'Hot Zeus',
  },
  {
    href: '#',
    image: '/images/myths-games/wild-heroes.jpg',
    name: 'Wild Heroes',
  },
  {
    href: '#',
    image: '/images/myths-games/great-eagle-of-zeus.jpg',
    name: 'Great Eagle of Zeus',
  },
  {
    href: '#',
    image: '/images/myths-games/nefertitis-long-story.jpg',
    name: "Nefertiti's Long Story",
  },
  {
    href: '#',
    image: '/images/myths-games/cleopatras-story.jpg',
    name: "Cleopatra's Story",
  },
];
