import clsx from 'clsx';
import { Container } from '@/shared/ui/container';
import { Typography } from '@/shared/ui/typography';
import { topGames } from './config/top-games.config';
import { irlandGames } from './config/irland-games.config';
import { GameItem } from '@/shared/ui/game-item';
import styles from './styles.module.scss';

const games = {
  top: topGames,
  irland: irlandGames,
};

interface ThreeGamesProps {
  isHotGames?: boolean;
  gamesType: keyof typeof games;
  title: string;
}

export const ThreeGames = ({
  isHotGames = false,
  gamesType,
  title,
}: ThreeGamesProps) => {
  const items = games[gamesType];

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div
          className={clsx(styles.wrapper, { [styles.redStroke]: isHotGames })}
        >
          <Typography className={styles.title} tag="h2">
            {title}
            {isHotGames ? <img src="/images/fire.png" alt="🔥" /> : null}
          </Typography>
          <ul className={styles.items}>
            {items.map((item) => (
              <li key={item.name} className={styles.item}>
                <GameItem size="md" item={item} isRedStroke={isHotGames} />
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  );
};
