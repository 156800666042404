import { Container } from '@/shared/ui/container';
import { mythsLegendsGames } from './config/myths-legends.config';
import { fruitSlots } from './config/fruit-slots.config';
import { Typography } from '@/shared/ui/typography';
import { GameItem } from '@/shared/ui/game-item';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from './styles.module.scss';

const games = {
  myths: mythsLegendsGames,
  fruit: fruitSlots,
};

interface GridGamesProps {
  gamesType: keyof typeof games;
  title: string;
}

export const GridGames = ({ gamesType, title }: GridGamesProps) => {
  const items = games[gamesType];

  const [_, ...gamesWithoutFirstItem] = items;

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <Typography className={styles.title} tag="h2">
            {title}
          </Typography>
          <ul className={styles.items}>
            {items.map((item, idx) => (
              <li key={item.name} className={styles.item}>
                <GameItem size={idx === 0 ? 'xl' : 'sm'} item={item} />
              </li>
            ))}
          </ul>
          <Swiper
            centeredSlides
            loop
            slidesPerView={1}
            spaceBetween={10}
            className={styles.carousel}
          >
            {gamesWithoutFirstItem.map((item) => (
              <SwiperSlide key={item.name}>
                <GameItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </section>
  );
};
