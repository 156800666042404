import { Container } from '@/shared/ui/container';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import styles from './styles.module.scss';

export const ContactBtn = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <Button className={styles.btn} as="a" href="#contacts">
          {t('contact_us')}
        </Button>
      </Container>
    </div>
  );
};
