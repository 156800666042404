import styles from './styles.module.scss';

export const MainBg = () => {
  const circles = Array.from({ length: 20 }).map((_, idx) => idx);

  return (
    <div className={styles.circles}>
      {circles.map((circle) => (
        <div key={circle} className={styles.circle} />
      ))}
    </div>
  );
};
