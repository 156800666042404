import type { BaseGameItem } from './types';
import styles from './styles.module.scss';
import clsx from 'clsx';

interface GameItemProps {
  item: BaseGameItem;
  isRedStroke?: boolean;
  size?: 'xl' | 'md' | 'sm';
}

export const GameItem = ({
  item,
  isRedStroke = false,
  size = 'md',
}: GameItemProps) => {
  return (
    <a
      href={item.href}
      className={clsx(
        styles.root,
        { [styles.redStroke]: isRedStroke },
        styles[size],
      )}
    >
      <div className={styles.image}>
        <img src={item.image} alt={item.name} />
      </div>
      <h3 className={styles.name}>{item.name}</h3>
    </a>
  );
};
