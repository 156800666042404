import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import type { IconName } from '@/shared/ui/icon';
import { Icon } from '@/shared/ui/icon';
import { LS_LANG_KEY } from '@/shared/i18n/consts';
import styles from './styles.module.scss';
import { useState } from 'react';

interface LangPickerProps {
  className?: string;
}

export const LangPicker = ({ className }: LangPickerProps) => {
  const { i18n } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [_, ...languages] = i18n.languages;

  const onLangSelectorClick = () => {
    setIsOpened((prevState) => !prevState);
  };

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(LS_LANG_KEY, lang);
    setIsOpened(false);
  };

  return (
    <div
      className={clsx(styles.root, className, {
        [styles.isOpened]: isOpened,
      })}
    >
      <div
        className={styles.current}
        onClick={onLangSelectorClick}
        onBlur={() => setIsOpened(false)}
        tabIndex={0}
        role="button"
      >
        <Icon
          className={styles.flag}
          name={`flags/${i18n.language}` as IconName}
        />
        <div className={styles.name}>{i18n.language}</div>
        <Icon className={styles.arrow} name="common/arrow-down" />
      </div>
      <ul className={styles.dropdown}>
        {languages.map((lang) => (
          <li
            key={lang}
            role="presentation"
            onMouseDown={() => onChangeLanguage(lang)}
            className={clsx(styles.dropdownItem, {
              [styles.currentItem]: lang === i18n.language,
            })}
          >
            <Icon className={styles.flag} name={`flags/${lang}` as IconName} />
            {lang}
          </li>
        ))}
      </ul>
    </div>
  );
};
