import { Container } from '@/shared/ui/container';
import { useText } from '@/shared/context/text.context';
import styles from './styles.module.scss';

export const Hero = () => {
  const text = useText();

  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <h1 className={styles.title}>{text.hero.title}</h1>
      </Container>
    </section>
  );
};
