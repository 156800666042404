import type { IconName } from '@/shared/ui/icon';

interface SocialItem {
  href: string;
  name: string;
  icon: IconName;
}

export const socialItems: SocialItem[] = [
  // {
  //   href: '#',
  //   name: 'Viber',
  //   icon: 'flat-social/viber',
  // },
  {
    href: 'https://t.me/inrival',
    name: 'Telegram',
    icon: 'flat-social/telegram',
  },
  // {
  //   href: '#',
  //   name: 'WhatsApp',
  //   icon: 'flat-social/whatsapp',
  // },
];
