import type { TFunction } from 'i18next';

export const menuItems = (t: TFunction) => [
  {
    href: '#about-us',
    text: t('menu.about_us'),
  },
  // {
  //   href: '#',
  //   text: t('menu.games'),
  // },
  {
    href: '#contacts',
    text: t('menu.contacts'),
  },
];
