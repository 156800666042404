import type { BaseGameItem } from '@/shared/ui/game-item';

export const fruitSlots: BaseGameItem[] = [
  {
    href: '#',
    image: '/images/fruit-games/the-harvest-wheel.jpg',
    name: 'The harvest wheel',
  },
  {
    href: '#',
    image: '/images/fruit-games/fruit-heat.jpg',
    name: 'Fruit Heat',
  },
  {
    href: '#',
    image: '/images/fruit-games/searing-hot.jpg',
    name: 'Searing Hot',
  },
  {
    href: '#',
    image: '/images/fruit-games/phoenixs-fruits.jpg',
    name: "Phoenix's Fruits",
  },
  {
    href: '#',
    image: '/images/fruit-games/cloveromatic.jpg',
    name: 'Cloveromatic',
  },
  {
    href: '#',
    image: '/images/fruit-games/kingly-crown.jpg',
    name: 'Kingly Crown',
  },
  {
    href: '#',
    image: '/images/fruit-games/majesty-fruits.jpg',
    name: 'Majesty Fruits',
  },
  {
    href: '#',
    image: '/images/fruit-games/fruit-scapes.jpg',
    name: 'Fruit Scapes',
  },
  {
    href: '#',
    image: '/images/fruit-games/joker-goes-wild.jpg',
    name: 'Joker Goes Wild',
  },
  {
    href: '#',
    image: '/images/fruit-games/glaring-hot.jpg',
    name: 'Glaring Hot',
  },
  {
    href: '#',
    image: '/images/fruit-games/fruittastic.jpg',
    name: 'Fruittastic',
  },
  {
    href: '#',
    image: '/images/fruit-games/fruity-taste.jpg',
    name: 'Fruity Taste',
  },
  {
    href: '#',
    image: '/images/fruit-games/phoenix-wild.jpg',
    name: 'Phoenix Wild',
  },
  {
    href: '#',
    image: '/images/fruit-games/infinitely-hot.jpg',
    name: 'Infinitely Hot',
  },
  {
    href: '#',
    image: '/images/fruit-games/upper-hot.jpg',
    name: 'Upper Hot',
  },
  {
    href: '#',
    image: '/images/fruit-games/firing-hot.jpg',
    name: 'Firing Hot',
  },
  {
    href: '#',
    image: '/images/fruit-games/world-of-fruits.jpg',
    name: 'World of Fruits',
  },
];
