import type { BaseGameItem } from '@/shared/ui/game-item';

export const irlandGames: BaseGameItem[] = [
  {
    href: '#',
    image: '/images/irland-games/hot-clover.jpg',
    name: 'Hot Clover',
  },
  {
    href: '#',
    image: '/images/irland-games/clower-wins.jpg',
    name: 'Clower Wins',
  },
  {
    href: '#',
    image: '/images/irland-games/irish-story-wheel.jpg',
    name: 'Irish Story Wheel (3x3)',
  },
];
