import { Container } from '@/shared/ui/container';
import { useText } from '@/shared/context/text.context';
import type { IconName } from '@/shared/ui/icon';
import { Icon } from '@/shared/ui/icon';
import styles from './styles.module.scss';

export const Benefits = () => {
  const text = useText();

  const items = Array.from({ length: 4 }).map((_, idx) => `item_${idx + 1}`);

  const benefits = items.map((item) => ({
    key: item,
    text: text.benefits[item],
  }));

  return (
    <section id="about-us" className={styles.root}>
      <Container className={styles.container}>
        <img className={styles.image} src="/images/clover.png" alt="Clover" />
        <p
          className={styles.desc}
          dangerouslySetInnerHTML={{ __html: text.benefits.description }}
        />
        <ul className={styles.list}>
          {benefits.map((benefit) => (
            <li className={styles.item} key={benefit.key}>
              <Icon
                className={styles.icon}
                name={`benefits/${benefit.key}` as IconName}
              />
              {benefit.text}
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};
